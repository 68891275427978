import { Alert, Snackbar } from '@mui/material'
import styled, { css } from 'styled-components'

export const SnackbarStyled = styled(Snackbar)<{ isError: boolean }>`
  ${({ isError }) =>
    isError &&
    css`
      width: 100%;
      bottom: 0px;
      height: 100px;
      isolation: isolate;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        z-index: -1;
        left: 0;
        width: 100%;
        height: 130px; /* Height of the shadow */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
        pointer-events: none;
      }
    `};
`
export const AlertStyled = styled(Alert)<{ isError: boolean }>`
  ${({ isError, theme }) =>
    isError &&
    css`
      width: 60%;
      margin: 0 auto 20px;
      box-shadow: ${theme.shadows[3]};
      border-radius: 8px;
    `};
`
